import { render, staticRenderFns } from "./syncYxExam.vue?vue&type=template&id=2e0fbd6e&scoped=true"
import script from "./syncYxExam.vue?vue&type=script&lang=js"
export * from "./syncYxExam.vue?vue&type=script&lang=js"
import style0 from "./syncYxExam.vue?vue&type=style&index=0&id=2e0fbd6e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e0fbd6e",
  null
  
)

export default component.exports